<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-building"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Institutions List
                    </div>
                    <div class="text-sm text-500">
                      List of accredited institutions
                    </div>
                  </div>
                  <div class="ml-4">
                    <import-data
                      label="Select a file to import"
                      ref="dataimport"
                      upload-path="caps_institutions_list/importdata"
                      @importComplete="importComplete"
                    >
                      <Button
                        @click="$refs.dataimport.openDialog()"
                        icon="pi pi-folder"
                        label="Import Data"
                        class="p-button-success"
                      />
                    </import-data>
                    <div>
                      <a
                        target="_blank"
                        href="https://capsstorage.s3.us-west-2.amazonaws.com/templates/caps_institutions_import_template.xlsx"
                      >
                        <Button
                          label="Download import template"
                          class="p-button-link px-0 py-0 text-sm"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4 text-right">
              <Button
                label="Add New Institution"
                icon="pi pi-plus"
                @click="
                  openPageDialog(
                    {
                      page: 'caps_institutions_list/add',
                      url: `/caps_institutions_list/add`,
                    },
                    { persistent: true, closeBtn: true }
                  )
                "
                class="w-fullbg-primary"
              />
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section mb-3">
      <div class="container-fluid">
        <div class="grid">
          <div class="col-12 comp-grid">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search" />
              <InputText
                outlined
                dense
                placeholder="Search ..."
                class="w-full"
                v-model="searchInput"
              />
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div
                v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente"
              >
                Search
                <Chip
                  class="font-medium px-2 py-1"
                  removable
                  @remove="clearSearch()"
                  >{{ searchText }}</Chip
                >
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb
                    :home="{ icon: 'pi pi-home', to: '/' }"
                    :model="pageBreadCrumb"
                  />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records" v-if="!loading">
                      <DataTable
                        :lazy="true"
                        :loading="loading"
                        v-model:selection="selectedItems"
                        :value="records"
                        dataKey="caps_institution_id"
                        @sort="onSort($event)"
                        class=""
                        :showGridlines="false"
                        :rowHover="true"
                        responsiveLayout="stack"
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle="width: 3em"
                        ></Column>
                        <Column field="logo" header="Logo">
                          <template #body="{ data }">
                            <image-viewer
                              image-size="medium"
                              image-preview-size=""
                              :src="data.logo"
                              width="30px"
                              height="30px"
                              :num-display="1"
                            >
                            </image-viewer>
                          </template>
                        </Column>
                        <Column
                          field="institution_name"
                          header="Institution Name"
                        >
                          <template #body="{ data }">
                            <router-link
                              :to="`/caps_institutions_list/view/${data.caps_institution_id}`"
                            >
                              {{ data.institution_name }}
                            </router-link>
                          </template>
                        </Column>
                        <Column field="telephone" header="Telephone">
                          <template #body="{ data }">
                            <a
                              class="p-button-text"
                              :href="'tel:' + data.telephone"
                              >{{ data.telephone}}</a
                            >
                          </template>
                        </Column>
                        <Column field="location" header="Location">
                          <template #body="{ data }">
                            {{ data.location }}
                          </template>
                        </Column>
                        <Column
                          headerStyle="width: 3em"
                          headerClass="text-center"
                        >
                          <template #body="{ data }">
                            <Menubar
                              class="p-0"
                              ref="actionMenu"
                              :model="getActionMenuModel(data)"
                            />
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div>
                        <DataTable
                          :value="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
                          responsiveLayout="scroll"
                        >
                          <Column style="width: 3rem">
                            <template #body>
                              <Skeleton shape="circle" size="2rem" />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton borderRadius="16px" />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column style="width: 3rem">
                            <template #body>
                              <Skeleton size="2rem" />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <template v-if="!loading && !records.length">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        No record found
                      </div>
                    </template>
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div
                              class="flex justify-content-center flex-grow-0"
                            >
                              <div v-if="selectedItems.length" class="m-2">
                                <Button
                                  @click="deleteItem(selectedItems)"
                                  icon="pi pi-trash"
                                  class="p-button-danger"
                                  title="Delete Selected"
                                />
                              </div>
                              <div
                                class="m-2"
                                v-if="exportButton && records.length"
                              >
                                <Button
                                  @click="
                                    (event) => $refs.exportMenu.toggle(event)
                                  "
                                  label="Export"
                                  class="py-3"
                                  title="Export"
                                  icon="pi pi-print"
                                />
                                <Menu
                                  ref="exportMenu"
                                  popup
                                  :model="pageExportFormats"
                                />
                              </div>
                            </div>
                            <div
                              v-if="paginate && totalPages > 1"
                              class="flex-grow-1 my-1"
                            >
                              <Paginator
                                @page="
                                  (event) => {
                                    pagination.page = event.page + 1;
                                  }
                                "
                                :rows="pagination.rowsPerPage"
                                :totalRecords="totalRecords"
                              >
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b
                                      >{{ recordsPosition }} of
                                      {{ totalRecords }}</b
                                    >
                                  </span>
                                </template>
                                <template #end>
                                  <Dropdown v-model="pagination.rowsPerPage"
                                    :options="[5, 10, 15, 20, 30, 40, 50, 100, 200]">
                                  </Dropdown>  
                                </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { ListPageMixin } from "../../mixins/listpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  name: "listCapsinstitutionslistPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "caps_institution_id",
    },
    pageName: {
      type: String,
      default: "caps_institutions_list",
    },
    routeName: {
      type: String,
      default: "caps_institutions_listlist",
    },
    apiPath: {
      type: String,
      default: "caps_institutions_list/index",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: true,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
    limit: {
			type: Number,
			default: 40,
		},
  },
  data() {
    return {
      pageExportFormats: [
        {
          label: "Excel",
          icon: "pi pi-file-excel text-green-500",
          command: () => {
            this.exportPageRecords("excel", "xlsx");
          },
        },
        {
          label: "Csv",
          icon: "pi pi-table text-green-200",
          command: () => {
            this.exportPageRecords("csv", "csv");
          },
        },
      ],
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "Caps Institutions List";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["caps_institutions_list/records"];
      },
      set: function (value) {
        this.$store.commit("caps_institutions_list/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["caps_institutions_list/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("caps_institutions_list/setCurrentRecord", value);
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("caps_institutions_list", ["fetchRecords", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "Details",
          // command: (event) => {
          //   this.openPageDialog(
          //     {
          //       page: "caps_institutions_list/viewmore",
          //       url: `/caps_institutions_list/viewmore/${data.caps_institution_id}`,
          //     },
          //     { closeBtn: true }
          //   );
          // },
          to:  `/caps_institutions_list/view/${data.caps_institution_id}`,
          icon: "pi pi-eye",
        },
        {
          label: "Edit",
          command: (event) => {
            this.openPageDialog(
              {
                page: "caps_institutions_list/edit",
                url: `/caps_institutions_list/edit/${data.caps_institution_id}`,
              },
              { persistent: true, closeBtn: true }
            );
          },
          icon: "pi pi-pencil",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
